import React from "react";
import SEO from "../components/seo";
import Main from "../components/main";

const AboutPage = () => (
  <>
    <SEO title="About me" keywords={[`about`, `qi`, `qichen`]} />
    <Main title={"About me"} about />
  </>
);

export default AboutPage;
